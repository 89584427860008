* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

button {
    padding: 8px 24px;
    color: white;
    background-color: black;
    border-radius: 50px;
    font-size: 1rem;
    font-weight: bold;
    cursor: pointer;
    transition: 250ms ease-out;
    outline: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

button:hover {
    background-color: rgba(0, 0, 0, 0.75);
}

button:active {
    background-color: rgba(0, 0, 0, 0.6);
}

