.container {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 20%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.joke {
    width: 100%;
    height: 200px;
    border: 2px solid #e2e2e2;
    border-radius: 32px;
    margin-bottom: 32px;
    box-shadow: 0 4px 20px #00000011;
    
    padding: 1em 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.joke h1 {
    font-size: 1.5em;
}

@media screen and (max-width: 768px) {
    .container {
        width: 90%;
        margin: 0 5%;
    }
    
    .joke {
        height: 300px;
    }
}
